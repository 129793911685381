*,
*::before,
*::after {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}

html {
    font-family: Soleil, sans-serif;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;

    background-color: rgb(244, 244, 255);
    background-color: rgba(244, 244, 255, 0.7);
    background-image: url('../assets/images/background_pattern.png');
    background-size: 32px;
}

button {
    background-color: unset;
}

a {
    text-decoration: none;
}

#root {
    width: 100%;
    height: 100vh;
}

::selection {
    color: #ffffff;
    background: #000000;
}
